
import { url } from "./baseurl";
import axios from "axios";


// showrooms API
export const getShowrooms = async () => {
  try {
    const response = await axios.post(url + "/showroom", {action : 'getActiveShowrooms'});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};


// Events API
export const getEvents = async () => {
  try {
    const response = await axios.post(url + "/events", {action : 'getEventsName'});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};


// Team API
export const getTeams = async (id) => {
  try {
    const response = await axios.post(url + "/teams", {action : 'getShowroomTeams', id});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};


// Events API
export const getEventData = async (id) => {
  try {
    const response = await axios.post(url + "/events", {action : 'getEventData', id});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};


// Events API
export const getBookingData = async (data) => {
  try {
    const response = await axios.post(url + "/schedule", {action : 'getBookingData', data});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};


// Events API
export const addBookingData = async (data) => {
  try {
    const response = await axios.post(url + "/schedule", {action : 'addSchedule', data});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};

// Events API
export const editBookingData = async (data) => {
  try {
    const response = await axios.post(url + "/schedule", {action : 'editSchedule', data});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};



export const getSingleBookingData = async (id) => {
  try {
    const response = await axios.post(url + "/schedule", {action : 'GetSingleBookingData', id});
    return response.data;
  } catch (error) {
    throw new Error(`Failed to send data: ${error.message}`);
  }
};