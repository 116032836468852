import React, { useState, useMemo, useEffect } from "react";
import CalendarComponent from "./CalendarComponent";
import SlotSelectionComponent from "./SlotSelectionComponent";
import { generateExcludedDates, getMaxDate, isToday } from "./utils";
import './Calender.css'

const Main = ({ formData, setFormData, errors, setErrors,  setBookingData,  bookingData, eventData, teamData}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeStartDate, setActiveStartDate] = useState(new Date());

  const [excludedDates, setExcludedDates] = useState([])

  // Generate excluded dates using memoizatio  


useEffect(() => {
  
  if (!bookingData || !Object.keys(eventData).length || !teamData.length) {
    return;
  }
  // Generate excluded dates and updated booking data
  const { updatedBookingData, excludedDates } = generateExcludedDates(bookingData, eventData, teamData);
  // Only update booking data if it has changed to avoid unnecessary re-renders
  if (JSON.stringify(bookingData) !== JSON.stringify(updatedBookingData)) {
    setBookingData(updatedBookingData);
  }
  
  // Update the excludedDates directly (no need for useMemo)
  setExcludedDates(excludedDates);
}, [bookingData, eventData, teamData]);


  // Max date for booking
  const maxDate = eventData?.day_limit ? getMaxDate(eventData.day_limit) : null;

  // Handle date change
  const handleDateChange = (date) => {
    
    formData.slot_time = ""; // Reset slot on date change
    formData.team_id = ""; // Reset team on date change
    const formattedDate = date.toLocaleDateString("en-CA");
    setSelectedDate(formattedDate);

    setFormData({ ...formData, slot_date: formattedDate });

    // Clear errors
    if (errors.slot_date) {
      setErrors((prevErrors) => {
        const { slot_date, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  
  
  return (
    <div className="calendar-container">
      {(bookingData && eventData && teamData) && <CalendarComponent
        selectedDate={selectedDate}
        excludedDates={excludedDates}
        maxDate={maxDate}
        activeStartDate={activeStartDate}
        setActiveStartDate={setActiveStartDate}
        handleDateChange={handleDateChange}
        isToday={isToday}
        formData={formData}
      />}
      
      {(bookingData && eventData && teamData && formData.slot_date) && <SlotSelectionComponent
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          selectedDate={formData.slot_date}
          bookingData={bookingData}
          eventData={eventData}
          teamData={teamData}
        />}
   

      {errors.slot_date && <p className="error-message">{errors.slot_date}</p>}
    </div>
  );
};

export default Main;
