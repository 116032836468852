import React, { useState, useEffect } from "react";
import Eventtype from "../../Components/Eventtype/Eventtype";
import Nav from "../../Components/Nav/Nav";
import Location from "../../Components/Location/Location";
import Calendar from "../../Components/Calendar/Main";
import "./Form.css";
import {
  getShowrooms,
  getEvents,
  getTeams,
  getEventData,
  getBookingData,
  addBookingData,
  getSingleBookingData,
  editBookingData,
} from "../../services/api/api";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

function Form() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id"); // Get the 'id' parameter

  const [bookingData, setBookingData] = useState([]);
  const [eventData, setEventData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [eventsOption, setEventsOption] = useState([]);
  const [showroomOption, setShowroomOption] = useState([]);
  const [page, setPage] = useState(0);
  const FormTitles = ["Show Room", "Slot Details", "Location Details"];
  const [loading, setLoading] = useState(false); // State for loading
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});


  

  useEffect(() => {
    const fetchTeamData = async (id) => {
      try {
        const data = await getTeams(id);
        if (data.status) {
          setTeamData(data.data);
        }else{
          setTeamData([]);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    };

    const fetchEventData = async (id) => {
      try {
        const data = await getEventData(id);
        if (data.status) {

          console.log(data);
          setEventData(data.data);

          setFormData((prevFormData) => ({
            ...prevFormData,
            duration: data.data.duration,
          }));
        }else{
          setEventData({});

          setFormData((prevFormData) => ({
            ...prevFormData,
            duration: "",
          }));
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    };

    const fetchBookingData = async (payload) => {
      
      try {
        const data = await getBookingData(payload);
        
        if (data.status) {
          setBookingData(data.data);
        }else{
          setBookingData([]);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    };

    if (formData.showroom_id && formData.event_id) {

      fetchTeamData(formData.showroom_id);
      fetchEventData(formData.event_id);
      fetchBookingData({
        event_id: formData.event_id,
        showroom_id: formData.showroom_id,
      });
    }
  }, [formData.showroom_id, formData.event_id]); // Only runs on initial render

  useEffect(() => {
    const fetchEventsName = async () => {
      try {
        const data = await getEvents();

        if (data.status) {
          setEventsOption(data.data);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    };

    const fetchShowroomName = async () => {
      try {
        const data = await getShowrooms();
        if (data.status) {
          setShowroomOption(data.data);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    };

    fetchEventsName();
    fetchShowroomName();
  }, []); // Only runs on initial render

  useEffect(() => {
    if (id) {
      getSingleBookingData(id)
        .then((response) => {
          if (response.status) {
            setFormData(response.data);
          }
        })
        .catch((error) => {
          toast.error(error.message, {
            position: "top-right",
          });
        });
    } else {
      setFormData({
        id: "",
        event_id: "",
        showroom_id: "",
        slot_date: "",
        slot_time: "",
        designer_name: "",
        client_name: "",
        client_id: "",
        address: "",
        location: "",
        team_id: "",
        duration: "",
      });
    }
  }, []); // Only runs once on component mount

  const validateStep = () => {
    const newErrors = {};
    if (page === 0) {
      if (!formData.showroom_id) newErrors.showroom_id = "Showroom is required";
      if (!formData.event_id) newErrors.event_id = "Event Type is required";
    } else if (page === 1) {
      if (!formData.slot_date) newErrors.slot_date = "Date is required";
      if (formData.slot_date && !formData.slot_time)
        newErrors.slot_time = "Slot is required";
    } else {
      if (!formData.designer_name)
        newErrors.designer_name = "Designer Name is required";
      if (!formData.client_name)
        newErrors.client_name = "Client Name is required";
      if (!formData.client_id) newErrors.client_id = "Client ID is required";
      if (!formData.address)
        newErrors.address = "Address of the Property is required";
      if (!formData.location) {
        newErrors.location = "Google Location is required";
      } else {
        try {
          new URL(formData.location);
        } catch (e) {
          newErrors.location = "Invalid URL for Google Location";
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  console.log(eventsOption);
  console.log(showroomOption);
  console.log(bookingData);
  console.log(eventData);
  console.log(teamData);

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <Eventtype
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          eventsOption={eventsOption}
          showroomOption={showroomOption}
        />
      );
    } else if (page === 1) {
      return (
        <Calendar
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          setBookingData={setBookingData}
          bookingData={bookingData}
          eventData={eventData}
          teamData={teamData}
        />
      );
    } else {
      return (
        <Location
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      );
    }
  };
  const handleBack = () => {
    setPage((currPage) => currPage - 1); // Go to the previous page
  };

  const handleSubmit = async () => {
    if (page === FormTitles.length - 1) {
      if (validateStep()) {
        setLoading(true); // Set loading to true

        try {
          const response = formData.id
            ? await editBookingData(formData)
            : await addBookingData(formData); // Call the API
          if (response.status) {
            Swal.fire({
              title: `${response.message}`,
              icon: "success",
              confirmButtonColor: "#dc3545",
              confirmButtonText: "Okay",
              customClass: {
                title: "swal2-title-custom",
                htmlContainer: "swal2-text-custom",
                confirmButton: "swal2-confirm-button-custom",
              },
            }).then(() => {
                setPage(0);
                setFormData({});
                setLoading(false);
            });
          } else {
            toast.error(response.message, {
              position: "top-right",
            });
          }
        } catch (error) {
          toast.error(error.message, {
            position: "top-right",
          });
        }
      }
    } else {
      if (validateStep()) {
        setPage((currPage) => currPage + 1); // Go to the next page
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <Nav />
      <div className="form">
        <div className="form-container">
          {/* Progress Step */}
          <div className="progressbar">
            {FormTitles.map((title, index) => {
              // Log the current title and index

              return (
                <div key={index} className="step-container">
                  <div
                    className={`step ${page > index ? "completed" : ""} ${
                      page === index ? "active" : ""
                    }`}
                  >
                    <span>{page > index ? "✔" : index + 1}</span>{" "}
                    {/* Show checkmark for completed steps */}
                    <p>{title}</p>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Form Title */}
          <div className="header">
            <h1>{FormTitles[page]}</h1>
          </div>

          {/* Form Body */}
          <div className="body">{PageDisplay()}</div>

          {/* Navigation Buttons */}
          <div className="footer">
            <button disabled={page === 0} onClick={handleBack}>
              Back
            </button>
            <button disabled={loading} onClick={handleSubmit} >
              {page === FormTitles.length - 1 ? "Book a Slot" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
