import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'
import Form from './Pages/Form/Form';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={
          <Form/>
           } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
