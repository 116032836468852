import React from "react";
import "./Location.css";

function Location({ formData, setFormData, errors, setErrors }) {
  const handleInputChange = (field, value) => {
    // Update formData
    setFormData({ ...formData, [field]: value.trimStart() });

    // Remove the specific error for the field if it exists
    if (errors[field]) {
      setErrors((prevErrors) => {
        const { [field]: _, ...rest } = prevErrors; // Exclude the specific error
        return rest; // Return the rest of the errors
      });
    }
  };

  return (
    <div className="form-section">

      <div className={`form-group ${errors.designer_name ? "error" : ""}`}>
              <label>Designer Name</label>
              <input
                type="text"
                placeholder="Enter Designer Name"
                value={formData.designer_name}
                onChange={(event) => handleInputChange("designer_name", event.target.value)}
              />
            {errors.designer_name && <p className="error-message">{errors.designer_name}</p>}
      </div>


      <div className={`form-group ${errors.client_name ? "error" : ""}`}>
              <label>Client Name</label>
              <input
                type="text"
                placeholder="Enter Client Name"
                value={formData.client_name}
                onChange={(event) => handleInputChange("client_name", event.target.value)}
              />
            {errors.client_name && <p className="error-message">{errors.client_name}</p>}
      </div>


      <div className={`form-group ${errors.client_id ? "error" : ""}`}>
              <label>Client ID</label>
              <input
                type="text"
                placeholder="Enter Client ID"
                value={formData.client_id}
                onChange={(event) => handleInputChange("client_id", event.target.value)}
              />
            {errors.client_id && <p className="error-message">{errors.client_id}</p>}
      </div>

      <div className={`form-group ${errors.address ? "error" : ""}`}>
              <label>Address of the Property</label>
              <input
                type="text"
                placeholder="Enter Address of the Property"
                value={formData.address}
                onChange={(event) => handleInputChange("address", event.target.value)}
              />
            {errors.address && <p className="error-message">{errors.address}</p>}
      </div>

      <div className={`form-group ${errors.location ? "error" : ""}`}>
              <label>Google Location</label>
              <input
                type="url"
                placeholder="Enter Google Location"
                value={formData.location}
                onChange={(event) => handleInputChange("location", event.target.value)}
              />
            {errors.location && <p className="error-message">{errors.location}</p>}
      </div>

    </div>
  );
}

export default Location;
