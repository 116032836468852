import React from "react";

const SlotSelectionComponent = ({
  formData,
  setFormData,
  errors,
  setErrors,
  selectedDate,
  bookingData,
  eventData,
  teamData,
}) => {
  
  const weekday = new Date(selectedDate).toLocaleString("en-us", { weekday: "long" });
  const availableSlots = eventData.availability[weekday] || [];

  
  const bookedSlot = bookingData.find((entry) => entry.date === selectedDate);
  
  const slotsForDate = bookedSlot ? bookedSlot.slots : {};
  const availableSlotsByTeam = teamData.reduce((result, team) => {
    const bookedSlotsForTeam = slotsForDate[team.id] || [];
    availableSlots.forEach((slot) => {
      if (!bookedSlotsForTeam.includes(slot)) {
        result.push({ id: team.id, slot });
      }
    });
    return result;
  }, []);  

  console.log(availableSlotsByTeam);
  

  const convertTo12HourFormat = (time24) => {
    // Parse the 24-hour time
    let [hours, minutes] = time24.split(":");
    hours = parseInt(hours, 10);
  
    // Determine AM/PM
    const modifier = hours >= 12 ? "PM" : "AM";
  
    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert hours to 12-hour format (12 becomes 12, 13 becomes 1, etc.)
    minutes = minutes.padStart(2, "0"); // Ensure minutes are always two digits
  
    // Return the formatted time
    return `${hours}:${minutes} ${modifier}`;
  };
  const convertTo24HourFormat = (time12hr) => {
    const [time, modifier] = time12hr.split(" ");
  
    let [hours, minutes] = time.split(":").map(Number);
  
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    // Return time in HH:mm format
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };
  
  const uniqueSlots = availableSlotsByTeam.reduce((result, { id, slot }) => {
    if (!result.some((item) => item.slot === slot)) {
      result.push({ id, slot });
    }
    return result;
  }, []);

  const updatedSlotsUpdated = uniqueSlots.map(slot => ({
    ...slot,
    slot: convertTo12HourFormat(slot.slot)
  }));



  const parseTime = (time) => {
    const [hoursMinutes, modifier] = time.split(" ");
    let [hours, minutes] = hoursMinutes.split(":").map(Number);
  
    if (modifier === "PM" && hours !== 12) {
      hours += 12; // Convert PM to 24-hour format
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0; // Midnight case
    }
    return hours * 60 + minutes; // Return total minutes since midnight
  };

  const updatedSlotsUpdatedSort = updatedSlotsUpdated.sort((a, b) => parseTime(a.slot) - parseTime(b.slot));

  const handleSlotSelection = (slot_time, team_id) => {

    slot_time = convertTo24HourFormat(slot_time)

    setFormData({
      ...formData,
      slot_time,
      team_id,
    });

    if (errors.slot_time) {
      setErrors((prevErrors) => {
        const { slot_time, ...rest } = prevErrors;
        return rest;
      });
    }
  };


  return (
    <div className="timeslot-wrapper">
      <p>{new Date(selectedDate).toLocaleDateString("en-US", { weekday: "long", month: "long", day: "numeric" })}</p>
      <div className="time-slot-container">
        {updatedSlotsUpdated.length != 0 ? updatedSlotsUpdated.map(({ id, slot }, index) => (
          <button
            key={`${index}-${id}`}
            id={id}
            className={`time-slot ${formData.team_id === id && convertTo12HourFormat(formData.slot_time) === slot ? "selected" : ""}`}
            onClick={() => handleSlotSelection(slot, id)}
          >
            {slot}
          </button>
        )) : "There is no Slot, Please add Team"}
      </div>
      {errors.slot_time && <p className="error-message">{errors.slot_time}</p>}
    </div>
  );
};

export default SlotSelectionComponent;
