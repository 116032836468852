import React from "react";
import "./Eventtype.css";

function Eventtype({
  formData,
  setFormData,
  errors,
  setErrors,
  eventsOption,
  showroomOption,
}) {


  const handleInputChange = (field, value) => {
    // Update formData
    setFormData({ ...formData, [field]: value });

    // Remove the specific error for the field if it exists
    if (errors[field]) {
      setErrors((prevErrors) => {
        const { [field]: _, ...rest } = prevErrors; // Exclude the specific error
        return rest; // Return the rest of the errors
      });
    }
  };

  return (
    <div className="form-section">
      <div className={`form-group ${errors.event_id ? "error" : ""}`}>
        <label>Event Type</label>
        <select
          value={formData.event_id}
          onChange={(event) =>
            handleInputChange("event_id", event.target.value)
          }
        >
          <option value="" hidden>Select Event Type</option>
          {Array.isArray(eventsOption) && eventsOption.length > 0 ? (
            eventsOption.map((event) => (
              <option key={event.id} value={event.id}>
                {event.name}
              </option>
            ))
          ) : (
            <option value="" disabled>No available options</option>
          )}
        </select>
        {errors.event_id && (
          <p className="error-message">{errors.event_id}</p>
        )}
      </div>

      <div className={`form-group ${errors.showroom_id ? "error" : ""}`}>
        <label>Showroom</label>
        <select
          value={formData.showroom_id}
          onChange={(event) =>
            handleInputChange("showroom_id", event.target.value)
          }
        >
          <option value="" hidden>Select Showroom Name</option>
          {Array.isArray(showroomOption) && showroomOption.length > 0 ? (
            showroomOption.map((showroom) => (
              <option key={showroom.id} value={showroom.id}>
                {showroom.showroom_name}
              </option>
            ))
          ) : (
            <option value="" disabled>No available options</option>
          )}
        </select>
        {errors.showroom_id && <p className="error-message">{errors.showroom_id}</p>}
      </div>
    </div>
  );
}

export default Eventtype;
